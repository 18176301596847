<template>
  <div class="container h-100">
    <div class="row h-100 justify-content-center align-items-center">
      <div class="Login col-12 col-md-8 col-lg-6">
        <div class="Login__logo text-center">
          <img class="img-fluid" :src="logo" />
          <img class="img-fluid" :src="logoCEasy" />
        </div>
        <div class="Login__form text-center pt-4">
          <form autocomplete="off" novalidate @submit.prevent="login()">
            <!-- Username Field -->
            <div class="form-group">
              <div class="input-group mb-2">
                <input
                  type="text"
                  class="form-control"
                  :class="{ 'is-invalid': errors.username }"
                  :placeholder="$t('LOGIN_USERNAME')"
                  v-model="username"
                />
                <div class="input-group-append">
                  <div class="input-group-text" :class="{ 'is-invalid': errors.username }">
                    <Account />
                  </div>
                </div>
                <div class="invalid-feedback">
                  {{ $t(errors.username) }}
                </div>
              </div>
            </div>
            <!-- Passwrod Field -->
            <div class="form-group">
              <div class="input-group mb-2">
                <input
                  type="password"
                  class="form-control"
                  :class="{ 'is-invalid': errors.password }"
                  :placeholder="$t('LOGIN_PASSWORD')"
                  v-model="password"
                />
                <div class="input-group-append">
                  <div class="input-group-text" :class="{ 'is-invalid': errors.password }">
                    <Lock />
                  </div>
                </div>
                <div class="invalid-feedback">
                  {{ $t(errors.password) }}
                </div>
              </div>
            </div>
            <!-- Submit Button -->
            <div class="row">
              <div class="col-12 col-md-6 order-md-2 text-right mb-2">
                <button type="submit" :disabled="isAuthFetching" class="Login__submit-btn btn btn-primary">
                  {{ $t('LOGIN_SUBMIT') }}
                </button>
              </div>
              <div class="col-12 col-md-6 order-md-1 text-left">
                <div class="Login__forgot-link">
                  <router-link :to="{ name: 'resetpasswd' }">{{ $t('LOGIN_FORGOT_LINK') }}</router-link>
                </div>
              </div>
            </div>
          </form>
        </div>
        <div class="row">
          <div class="col text-danger text-center">{{ $t(authError) }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Account from 'vue-material-design-icons/Account.vue'
import Lock from 'vue-material-design-icons/Lock.vue'
import logo from '@/assets/logo_sparco.svg'
import logoCEasy from '@/assets/logo_customeasy_oriz.png'

export default {
  name: 'login',
  components: {
    Account,
    Lock
  },
  computed: {
    ...mapGetters(['isAuthFetching', 'authError'])
  },
  data() {
    return {
      logo,
      logoCEasy,
      errors: {},
      username: '',
      password: ''
    }
  },
  methods: {
    login() {
      this.errors = {}
      if (!this.username) {
        this.errors.username = 'LOGIN_ERROR_USERNAME_REQUIRED'
      }
      if (!this.password) {
        this.errors.password = 'LOGIN_ERROR_PASSWORD_REQUIRED'
      }
      if (Object.keys(this.errors).length === 0) {
        this.$store.dispatch('AUTH_LOGIN', { username: this.username, password: this.password }).then(res => {
          if (res === true) {
            this.$router.push({ name: 'dashboard' })
          }
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
.Login {
  background: $c-white;
  border-top: 5px solid $c-blue;
  padding: 30px;

  &__logo {
    img {
      width: 45%;
      &:first-child {
        margin-right: 5%;
      }
    }
  }

  &__form {
    .form-control {
      color: $c-grey3;
      font-size: 16px;
      background: $c-grey2;
      border: none;
      border-radius: 0;
      padding-right: 0;
      padding-top: 8px;
      &.is-invalid {
        border: 1px solid $c-error !important;
        border-right: none !important;
      }
    }
    .input-group-text {
      color: $c-grey3;
      font-size: 20px;
      background: $c-grey2;
      border: none;
      border-radius: 0;
      &.is-invalid {
        border: auto;
        border: 1px solid $c-error;
        border-left: none;
      }
    }
  }

  &__submit-btn {
    background: $c-blue;
    border: none;
    border-radius: 0;
    width: 100%;
  }

  &__forgot-link {
    font-size: 16px;
    padding-top: 6px;
    a {
      &:focus,
      &:visited,
      &:active {
        color: $c-grey3;
      }
      &:hover {
        color: $c-blue;
      }
      text-decoration: none;
      color: $c-grey3;
    }
  }
}
</style>
